<template>
  <el-select
      :v-model="search"
      :value="selected"
      @input="dispatch"
      :placeholder="plc || 'Партнёр доктор'"
      filterable
      clearable
  >
    <el-option
        v-for="item in filtered"
        :key="item.id"
        :label="item.name"
        :value="item.id"
    ></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import inventory from "@/utils/mixins/components/inventory";

export default {
  mixins: [inventory],
  props: ["partner_clinic_id", "id", "open"],
  async mounted() {
    if (this.doctors && this.doctors.length === 0) {
      await this.getInventory({column: "name", order: "asc" });
    }
  },
  watch: {
    partner_clinic_id: {
      handler: function (val, oldVal) {
        this.getInventory({ column: "name", order: "asc" });
        this.selected = null;
        this.$emit("input", this.selected);
      },
    },
    id: {
      handler: function () {
        this.selected = this.id;
      },
      deep: true,
      immediate: true,
    },
    open: {
      handler: function (newValue, oldValue) {
        if (newValue === true) {
          this.selected = this.id;
          this.getPartnerDoctors({ column: "name", order: "asc" });
        }
      }
    }
  },
  data() {
    return {
      search: ''
    }
  },
  computed: {
    ...mapGetters({
      doctors: "partnerDoctor/get_partner_doctors",
    }),
    filtered: function () {
      return this.doctors;

      // if (this.partner_clinic_id) {
      //   return this.doctors.filter((o) => {
      //     return o.partner_clinic_ids.includes(this.partner_clinic_id);
      //   });
      // } else {
      //   return this.doctors;
      // }
    },
  },
  methods: {
    ...mapActions({
      getInventory: "partnerDoctor/inventory",
      getPartnerDoctors: "partnerDoctor/get",
    }),
  },
};
</script>
